@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&family=Source+Sans+Pro:wght@300;400;600;700&display=swap');

@import url('http://fonts.cdnfonts.com/css/neue-haas-grotesk-display-pro');

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

*{
box-sizing: border-box;
 font-family: 'Neue Haas Grotesk Display Pro','Source Sans Pro', 'Fira Sans', 'Open Sans', 'Helvetica Neue',sans-serif;
}
body {
  margin: 0;
  font-family: 'Neue Haas Grotesk Display Pro','Source Sans Pro', 'Fira Sans', 'Open Sans', 'Helvetica Neue',
    sans-serif;
    font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background:"#feffe";
 word-spacing: 0.2em;
  scroll-behavior: smooth;

}

input{
    font-family: 'Neue Haas Grotesk Display Pro','Source Sans Pro', 'Fira Sans', 'Open Sans', 'Helvetica Neue',
    sans-serif;
       word-spacing: 0.2em;
}

button{
  font-family: 'Neue Haas Grotesk Display Pro','Source Sans Pro', 'Fira Sans', 'Open Sans', 'Helvetica Neue',
    sans-serif;
    word-spacing: 0.2em;
}

.App{
  /* max-width: 1200px; */
  margin:0 auto;
}
